<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <div class="box-card">
                <!-- 按钮 -->
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="按月份筛选">
                        <div class="block">
                              <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.month"
                                type="month"
                                format="yyyy-MM"
                                value-format="yyyy-MM"
                                placeholder="选择月份">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">搜索</el-button>
                        <!-- <el-button @click="onReset">重置</el-button> -->
                    </el-form-item>
                </el-form>

                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
        
        
        
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width: 96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="店铺"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.name }}</template>
                    </el-table-column>
                    <el-table-column label="快递明细"  min-width="65" align="center">
                        <template slot-scope="scope" >
                            <div style="color:blue;cursor:pointer;" @click="showDetail(scope.row.id)">
                                <div v-for="(i,k) in scope.row.express" :key="k" >
                                    <span>快递公司：</span> <span>{{i.name}}：</span> 
                                    <span></span> <span>{{i.count}}</span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    
                    <el-table-column label="快递公司数量"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.express_count }}</template>
                    </el-table-column>

                    <el-table-column label="操作"  min-width="65" align="center">
                        <template slot-scope="scope">
                            <div style="color:blue;cursor:pointer;" @click="showDetail(scope.row.id)">详情</div>
                        </template>
                    </el-table-column>
                                        
                </el-table>

                <!-- 页码 -->
            </div>
        </div>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="添加员工"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="60%"
        center>
            <createShare  :id="currentId"  @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>

        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="日志详情"
        :visible.sync="shareListFlag"
        width="80%"
        center>
            <shareList :month="req.month" :shop_id="currentId"></shareList>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareList from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
let d = new Date();
let month = tools.getMM();
let yearMonth = d.getFullYear()+'-'+month;
export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            //上传图片列表地址
            uploadImages: [

            ],
            createShareFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            req:{
                month:yearMonth
            }
        }
    },
    components:{
        createShare,shareList
    },
    methods:{
        onSubmit(){
            this.getList();
        },
        showDetail(shop_id){
            this.shareListFlag = true;
            this.currentId = shop_id;
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            apis.getExpressLog(this.req).then((res) => {
                this.tableData = res.data;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        remove(id, key) {
            this.$confirm('确定删除此销售单?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.staffDelete({ id: id}).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id){
            this.createShareFlag = true;
            this.currentId = id;
        }
    },
    created(){
        console.log(apis);
        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>