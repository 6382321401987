<template>
  <div>
      <!-- 搜索表单 -->
        <el-form :inline="true" class="demo-form-inline" >
            <el-form-item label="关键词" >
                <el-input v-model="req.keywords" size="small" style="width:150px"></el-input>
            </el-form-item>            
            <el-form-item label="快递公司" >
                <el-select v-model="req.cp_code" size="small" @change="getList" placeholder="请选择" style="width:120px;">
                    <el-option
                            v-for="item in expressList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- <el-form-item label="店铺" >
                <el-select v-model="req.shop_id" size="small"  @change="getList" placeholder="请选择" style="width:150px;">
                    <el-option
                            v-for="item in shopList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item> -->

            <!-- <el-form-item label="按月份筛选">
                <div class="block">
                    <div style="display:inline-block;">
                        <span class="demonstration"></span>
                        <el-date-picker
                        size="small"
                        v-model="req.month"
                        type="month"
                        format="yyyy-MM"
                        value-format="yyyy-MM"
                        placeholder="选择月份">
                        </el-date-picker>
                    </div>
                </div>
            </el-form-item> -->
            <el-form-item>
                <el-button type="primary" size="small" @click="getList">搜索</el-button>
                <!-- <el-button @click="onReset">重置</el-button> -->
            </el-form-item>
        </el-form>
       <!-- 创建 -->
    <el-table
            ref="multipleTable"
                        :height="__tableHeight__"
        
        
        
            :data="tableData"
            stripe
            tooltip-effect="dark"
            style="width: 96%;text-align: center">
            <el-table-column label="快递单号"  min-width="65" align="center">
                <template slot-scope="scope" >{{ scope.row.waybill_code }}</template>
            </el-table-column>
            <el-table-column label="快递公司编号"  min-width="65" align="center">
                <template slot-scope="scope" >{{ scope.row.logistics_companies_info.code }}</template>
            </el-table-column>
            <el-table-column label="快递公司名称"  min-width="65" align="center">
                <template slot-scope="scope" >{{ scope.row.logistics_companies_info.name }}</template>
            </el-table-column>
            <el-table-column label="店铺名称"  min-width="65" align="center">
                <template slot-scope="scope" >{{ scope.row.shop_info.name }}</template>
            </el-table-column>

            <el-table-column label="电商订单ID"  min-width="65" align="center">
                <template slot-scope="scope" >{{ scope.row.orders_info?scope.row.orders_info.tid:'' }}</template>
            </el-table-column>

            <el-table-column label="获取面单号人员姓名"  min-width="65" align="center">
                <template slot-scope="scope" >{{ scope.row.create_info.username }}</template>
            </el-table-column>
            <el-table-column label="打印时间"  min-width="65" align="center">
                <template slot-scope="scope" >{{ scope.row.create_time }}</template>
            </el-table-column>

        <!-- <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
                <el-button type="text" size="small"  @click="remove(scope.row.id)">
                    删除
                </el-button>
                <el-button type="text" size="small"  @click="update(scope.row.id)">
                    编辑
                </el-button>
            </template>
        </el-table-column> -->
    </el-table>
    <!-- 页码 -->
    <div class="block" >
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
        </el-pagination>
    </div>
    <div style="height:60px;"></div>
  </div>
</template>

<script>
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
let d = new Date();
let month = tools.getMM();
let yearMonth = d.getFullYear()+'-'+month;
export default {
    props:{
        printId:{
            type:Number,
            default:0
        },
        shop_id:{
            type:Number,
            default:0
        },
        month:{
            type:String,
            default:yearMonth
        }
    },
    data(){
        return {
            tableData:[],
            expressList:[],
            shopList:[],
            total:0,
            currentPage:1,
            req:{
                limit:30,
                page:1,
                shop_id:'',
                month:this.month,
                cp_code:'',
                keywords:'',
                cp_code:'',
                shop_id:this.shop_id
            }
        }
    },
    methods:{
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            apis.getExpressPrintDetails(this.req).then((res) => {
                this.tableData = res.data.data;
                this.total = res.data.total;
            }).catch((err) => {
                tools.err(err,this);
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        remove(id, key) {
            this.$confirm('确定删除此销售单?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                shareDel({ id: id }).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        update(tplId,){
            
        }
    },
    created(){
        // 获取快递公司列表
        apis.expressList().then(res=>{
            if(tools.msg(res,this)){
                this.expressList = res.data;
            }
        }).catch(err=>{
            tools.err(err,this);
        });
        //店铺列表
        apis.shopList().then(res=>{
            if(tools.msg(res,this)){
                this.shopList = res.data.data;
                console.log(this.shopList);
            }
        }).catch(err=>{
            tools.err(err,this);
        });
        this.getList();
    }
}
</script>
<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>